import React, { useEffect, useRef } from 'react';
import './Spiral - Alias.css';

function SpiralAlias() {

  const canvas = useRef<HTMLCanvasElement>(null);


  useEffect(() => {
    if (canvas) {
      const context2D = canvas.current?.getContext('2d')
      if (context2D) render(context2D);
    }
  }, [canvas])

  return (
    <div className="App">
      <div className="bg1">
      </div>
      <div className="bg2">
      </div>
      <canvas ref={canvas} width={window.innerWidth} height={window.innerHeight - 5}></canvas>
    </div>
  );
}

const toRad = (dg: number): number => (Math.PI / 180) * dg;

const clear = (ctx: CanvasRenderingContext2D) => ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

var rotation = 0;
var directionForward = false;

function render(ctx: CanvasRenderingContext2D) {
  const w = window.innerWidth;
  const h = window.innerHeight;
const min = w <= h ? w : h; // Minimun size between width and height
  clear(ctx);

  // Configs   
  const radius = (min / 2.1)
  const tot = 100;
  const radiusDiff = 6;
  const maxArcSize = 60;
  const maxRotation = 100;
  const rotationStep = 0.1;


  // const rotationDiff = Math.random() * 100 + 30; // Random Figures

  // Revert direction if needed
  if (rotation === maxRotation || rotation === 0) directionForward = !directionForward;

  // Compute rotation Diff
  const rotationDiff = directionForward ? rotation += rotationStep : rotation -= rotationStep

  for (let i = 1; i <= tot; i++) {
    // Line Style
    ctx.strokeStyle = `rgb(200,  ${255 - (255 / tot * i)}, 0)`;
    ctx.lineWidth = 3;

    // Path
    ctx.beginPath();
    ctx.arc(w / 2, h / 2, Math.max(radius - (i * radiusDiff), 1), toRad(0 + ((rotationDiff - i) * rotationDiff)), toRad(maxArcSize + ((rotationDiff - i) * rotationDiff)));
    ctx.stroke();
  }

  requestAnimationFrame(() => render(ctx));
}

export default SpiralAlias;
