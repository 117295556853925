import React, { useEffect, useRef } from 'react';
import './Spiral - Purple.css';

function SpiralPurple(props: { noBg?: boolean }) {

  const canvas = useRef<HTMLCanvasElement>(null);


  useEffect(() => {
    if (canvas) {
      const context2D = canvas.current?.getContext('2d')

      if (context2D) {
        context2D.imageSmoothingQuality = "high";
        render(context2D);
      }
    }
  }, [canvas])

  return (
    <div>
      {/* <div style={{ color: 'white', position: 'absolute', top: '5px', left: '5px' }}>
        {window.innerWidth} - {window.innerHeight}
      </div> */}

      <div className="App">
        {
          props?.noBg
          ||
          <>
            <div className="bg1">
            </div>
            <div className="bg2">
            </div>
          </>
        }
        <canvas ref={canvas} width={window.innerWidth} height={window.innerHeight - 5}></canvas>
      </div>
    </div>
  );
}

const toRad = (dg: number): number => (Math.PI / 180) * dg;

const clear = (ctx: CanvasRenderingContext2D) => ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

var rotation = 0;
var directionForward = false;

function render(ctx: CanvasRenderingContext2D) {
  const w = window.innerWidth;
  const h = window.innerHeight;
  const min = w <= h ? w : h; // Minimun size between width and height

  clear(ctx);

  // Configs   
  const tot = 150; // Total Arcs
  const maxRotation = 90; // Max radius Rotation before invert direction
  const rotationStep = 1; // Valore di incremento/decremento rotazione in ogni frame
  const speedMultiplyer = 0.2; // Moltiplicatore velocità



  // Arc Config
  const radius = (min / 2.1) // Dimensione dell'arco più grande (esterno)
  const maxArcSize = 90;  // 360 = cerchio | 0 = niente
  const arcHole = (360 - maxArcSize);  // 360 = cerchio | 0 = niente
  const lineWidth = 3;  // 360 = cerchio | 0 = niente
  const radiusDiff = lineWidth * 0.9; // Radius Remover



  // const rotationDiff = Math.random() * 100 + 30; // Random Figures

  // Revert direction if needed
  if (rotation === maxRotation || rotation === -maxRotation) directionForward = !directionForward;

  // Compute rotation Diff
  const rotationDiff = directionForward ? rotation += (rotationStep * speedMultiplyer) : rotation -= (rotationStep * speedMultiplyer)

  for (let i = 1; i <= tot; i++) {
    // Line Style
    ctx.strokeStyle = `rgba(100, 0, ${255 - (255 / tot * i)}, ${Math.max(1 - (1 / tot * i), 0.15)})`;
    ctx.lineWidth = lineWidth;

    // Path
    ctx.beginPath();
    ctx.arc(
      w / 2,
      h / 2,
      Math.max(radius - (i * radiusDiff), 1), // Radius
      toRad((0 - (arcHole / 2)) + (rotationDiff * (i / 2))), // Start Angle
      toRad((maxArcSize - (arcHole / 2)) + (rotationDiff * (i / 2))) // End Angle
    );
    ctx.stroke();
  }

  requestAnimationFrame(() => render(ctx));
}

export default SpiralPurple;
