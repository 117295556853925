import React, { useEffect, useRef, useState } from 'react';

function Lines1() {

  const canvas = useRef<HTMLCanvasElement>(null);
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: -100 });

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (canvas) {
      const context2D = canvas.current?.getContext('2d')

      if (context2D) {
        render(context2D, position);
        interval = setInterval(() => {
          render(context2D, position);
        }, 1000 / 60)
      }
    }

    return () => { if (interval) clearInterval(interval) };
  }, [position, canvas]);

  useEffect(() => {
    const callback = (event: (MouseEvent | TouchEvent | any)) => {
      setPosition({ x: event?.clientX || event.touches[0].clientX, y: event.clientY || event.touches[0].clientY });
    };
    window.addEventListener('click', callback);
    window.addEventListener('touchstart', callback);
    window.addEventListener('touchend', callback);
    window.addEventListener('touchmove', callback);
    window.addEventListener('mousemove', callback);

    return () => {
      window.removeEventListener('click', callback);
      window.removeEventListener('touchstart', callback);
      window.removeEventListener('touchend', callback);
      window.removeEventListener('touchmove', callback);
      window.removeEventListener('mousemove', callback);
    };
  }, []);

  return (
    <div className="App">
      <canvas ref={canvas} width={window.innerWidth} height={window.innerHeight - 5}></canvas>
    </div>
  );
}

const clear = (ctx: CanvasRenderingContext2D) => ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

function render(ctx: CanvasRenderingContext2D, position: any) {
  const w = window.innerWidth;
  const h = window.innerHeight;

  const nLines = 200;

  clear(ctx);

  for (let i = 1; i <= nLines; i++) {
    ctx.lineWidth = (Math.random() * 1) + 1;

    const _end = { x: (Math.random() * w), y: Math.random() * h }

    var grd = ctx.createLinearGradient(position.x, position.y, _end.x, _end.y);
    const r = Math.random() * 255;
    const g = Math.random() * 255;
    const b = Math.random() * 255;
    grd.addColorStop(0, `black`);
    grd.addColorStop(1, `rgba(${r}, ${g}, ${b}, ${Math.max(0.9 - (1 / nLines * i), 0.15)})`);

    ctx.strokeStyle = grd;

    // const start = { x: w / 2, y: -100 };
    const start = { x: position.x, y: position.y };
    const end = _end;

    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();
  }
}


export default Lines1;
