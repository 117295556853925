import React, { useEffect, useRef, useState } from 'react';
import { clearCanvas, degreesToRadians } from '../../../utils/canvas-utils';
import { h_2, max, w_2 } from '../../../utils/size-utils';
import './Spiral - HawaiiPlus.css';


// Numero di spirali sulla canvas (Sperimentale e non implementato per ora [EDIT ME])
const nSpirals = 3;
// Utils array for mapping things
const spiralArr = Array(nSpirals).fill(null);
// Max Angle for a single spiral, base on nSpirals
const spiralDegrees = 360 / nSpirals;

// Base Arc Size in degrees
const baseArcSize = 1;

// Max Arc Size in degrees (keep in mind the number of spiral to avoid collision)
const maxArcSize = spiralDegrees - 0.1;

// Number of virtual rotation (used for compute maxAnimationValue)
const animNRotation = (nSpirals * 12);

// Actual rotation direction
var goClockwise = true;

// Reached value at which the direction is reversed (depends on baseArcSize)
const maxAnimationValue = 360 * animNRotation;

// Actual Animation Value (changes over time)
// var animationValue = maxAnimationValue;
var animationValue = 0;

// Incrase value for the animation (in the fastest moment)
const animationStep = 0.44;

// Rotation Multiplyer for smaller arcs
const smallerArcsSpeedMlt = 20;

const easeActiveThreshold = 1 - (0.05); // Do not edit 1 (is the "perc" value)

const minSpeedFactor = 0.05;

/**
 * Invert If Needed
 * 
 * If the animationValue reach the maxValue, inverts the direction
 */
const invertIfNeeded = () => {
  if (animationValue >= maxAnimationValue || animationValue <= -maxAnimationValue) goClockwise = !goClockwise;
}


// Total number of arcs per each spiral
const totSpiralArcs = max * 0.058; // Total Arcs

// External Arc Radius
const extRadius = (max / 1.5) // Dimensione dell'arco più grande (esterno)

// External Line Width
const extLineWidth = 4;

// Gap between each arcs layer
const radiusGap = extLineWidth * 3; // Radius Remover

// Color unit diff between each arcs layer (depends on totSpiralArcs)
const colorPerc = 255 / totSpiralArcs;

// Max Color Speed K
const maxColorSpeedK = 0.2;

export default function SpiralHawaiiPlus() {

  const canvas = useRef<HTMLCanvasElement>(null);
  const [dispose, setDispose] = useState(false);

  useEffect(() => {
    setDispose(false)
    if (canvas) {
      const context2D = canvas.current?.getContext('2d')

      if (context2D) {
        context2D.imageSmoothingQuality = "high";
        const loop = () => {
          render(context2D);
          if (!dispose) {
            requestAnimationFrame(() => loop());
          }
        }
        loop();
      }
    }
    return () => { setDispose(true); console.log('disposed') }
  }, [canvas, dispose])

  return (
    <div className="App Hawaii">
      <canvas ref={canvas} id="a" width={window.innerWidth} height={window.innerHeight - 5}></canvas>
    </div>
  );
}

/**
 * Render
 * 
 * Renderizza ogni frame dell'animazione in base al valore di animazione
 * @param ctx Context 2D nel quale viene renderizzata l'animazione
 */
function render(ctx: CanvasRenderingContext2D) {
  clearCanvas(ctx);

  // Invert direction if needed
  invertIfNeeded();

  const animPerc = (100 / maxAnimationValue) * animationValue * 0.01;

  // if (Math.random() < 0.001) console.log("animPerc", animPerc);

  let arcSizeDiff = Math.abs(animPerc * (maxArcSize - baseArcSize));

  // if (Math.random() < 0.00001) console.log("arcSizeDiff", animPerc);

  // Rotation Step Multiplier (Make speed faster in the center of the animation)
  let rotationStepMlt = 1;
  if (animPerc < 0 && animPerc < -easeActiveThreshold) {
    rotationStepMlt *= Math.min(Math.max(1, (1 + animPerc) * 10), minSpeedFactor);
  } else if (animPerc > easeActiveThreshold) {
    rotationStepMlt *= Math.max(Math.min(1, (1 - animPerc) * 10), minSpeedFactor);
  }
  rotationStepMlt = Math.min(1, Math.max(0.03, rotationStepMlt));

  if (Math.random() < 0.01) console.log("rotationStepMlt, animPerc", rotationStepMlt, animPerc);

  // Animation Step with ease-in-out  behaviour
  const animationStepEase = animationStep * rotationStepMlt;

  // Animation Value increment (or decrement)
  goClockwise
    ? animationValue += animationStepEase
    : animationValue -= animationStepEase;

  for (let i = 1; i <= totSpiralArcs; i++) {
    // Make each smaller arc, faster applying a multiplyer
    const rotationK = animationValue * (i / smallerArcsSpeedMlt);

    // Arc Radius
    const _radius = Math.max(extRadius - (i * radiusGap), 1);

    // Rotation Position
    const rKAngles = spiralArr.map((_, _i) => (rotationK + (_i * spiralDegrees)));

    // Actual Arc Angle Dimension
    const _arcSize = baseArcSize + arcSizeDiff;

    // if (Math.random() < 0.001) console.log("_arcSize", _arcSize);

    // Start Arc Angle Position
    const startPositions = spiralArr.map((_, _i) => degreesToRadians(rKAngles[_i]));
    // End Arc Angle Position
    const endPositions = spiralArr.map((_, _i) => degreesToRadians((rKAngles[_i] + _arcSize)));

    // Color Percentage
    const _cPerc = colorPerc * i;

    // Colors

    // GreenWater - Purple
    const y = 255 - (_cPerc);
    const waterGreen = `rgba(40, ${255 - _cPerc}, 110)`;
    const green = `rgba(20, ${255 - _cPerc}, ${y})`;
    // Fuxia - BluePurple
    const redFuxia = `rgba(${255 - (_cPerc)}, 0, 75 )`;    // Clone
    // Inverse Yellow
    // Yellow
    const yellow = `rgba(${y}, ${y}, 0  )`;    // Clone
    const orange = `rgba(${y}, ${y / 1.5}, 0  )`;    // Clone
    const purple = `rgba(${y / 3}, 30, ${y}  )`;    // Clone
    const blue = `rgba(15, 30, ${y * .9})`;    // Clone

    // Colors list
    const colors = [
      waterGreen,
      redFuxia,
      yellow,
      purple,
      orange,
      blue,
      green
    ];

    for (let s = 0; s < nSpirals; s++) {
      // Spiral Color
      const _c = getColor(s, colors);

      // Line Style
      ctx.strokeStyle = _c;

      // Line Width
      ctx.lineWidth = extLineWidth - (0.6 - ((0.6 / totSpiralArcs) * s));

      // Define Arc
      ctx.beginPath();
      ctx.arc(
        w_2,
        h_2,
        _radius, // Radius
        startPositions[s], // Start Angle
        endPositions[s] // End Angle
      );
      // Draw Stroke Arc
      ctx.stroke();
    }
  }
}

/**
 * Get Color
 * 
 * Restituisce un colore in base all'indice, alternando in sequenza i colori
 * con velocità relativa alla rotazione attuale
 * @param index 
 * @param colors 
 * @returns 
 */
function getColor(index: number, colors: string[]): string {
  // let _index = index;
  // const speed = maxColorSpeedK - Math.min(((maxColorSpeedK / maxAnimationValue) * animationValue), maxColorSpeedK);
  // const dominantLenght = nSpirals * 67;
  // const maxIndex = nSpirals - 1;
  // const k = Math.floor((nSpirals / maxAnimationValue) *  animationValue * speed) ;
  // const mod = (k % dominantLenght);

  // // if (_index > maxIndex) _index -= maxIndex;
  // // else if (_index < 0) _index += maxIndex;

  // let i = mod + index;
  const speed = maxColorSpeedK - Math.min(((maxColorSpeedK / maxAnimationValue) * animationValue), maxColorSpeedK);
  const module3 = 2 - Math.ceil((animationValue * speed) % nSpirals);


  const plus = module3 + index;

  let i: number = plus;

  if (plus > 2) i -= 3;
  else if (plus < 0) i += 3;

  // if (Math.random() < 0.0001) console.log(i, index, animationValue);

  return colors[i];
}

