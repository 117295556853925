import React from 'react';
import { NavLink } from 'react-router-dom';
import SpiralHawaii from './amazing-thinks/spirals/hawaii/Spiral - Hawaii';

export default function Home() {
    return (
        <div>
            <div className='home' style={{ color: 'white', padding: '100px 100px 200px 100px', zIndex: 10, display: 'flex', flexDirection: 'column', position: 'absolute', top: 0, left: 0, }}>
                <h3>Spirals</h3>
                <NavLink to="/Spiral/Purple" >Purple</NavLink>
                <NavLink to="/Spiral/Alias" >Alias</NavLink>
                <NavLink to="/Spiral/Hawaii" >Hawaii</NavLink>
                <NavLink to="/Spiral/HawaiiPlus" >Hawaii+ [New]</NavLink>
                <h3>Lines</h3>
                <NavLink to="/Lines/One" >One</NavLink>
                <NavLink to="/Lines/Interstellar" >Interstellar (alpha version) [New]</NavLink>
            </div>
            <div style={{ zIndex: 1, position: 'absolute', top: 0, left: 0, minWidth: '100vw', minHeight: '100vh' }}>
                <SpiralHawaii slow />
            </div>
        </div>
    );
}