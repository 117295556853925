
/**
 * Clear Canvas
 * 
 * Ripulisce l'intera superficie della canvas.
 * @param ctx CanvasRenderingContext2D
 * @returns void
 */
export const clearCanvas = (ctx: CanvasRenderingContext2D): void => ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);


export const degreesToRadians = (dg: number): number => (Math.PI / 180) * dg;
