import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Lines1 from './amazing-thinks/lines/1/lines_1';
import SpiralHawaii from './amazing-thinks/spirals/hawaii/Spiral - Hawaii';
import SpiralAlias from './amazing-thinks/spirals/alias/Spiral - Alias';
import SpiralPurple from './amazing-thinks/spirals/purple/Spiral - Purple';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Home';
import SpiralHawaiiPlus from './amazing-thinks/spirals/hawaii-plus/Spiral - HawaiiPlus';
import LinesInterstellar from './amazing-thinks/lines/interstellar/Lines - Interstellar';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/'
          element={<Home/>}>
        </Route>
        {/* SPIRALS */}
        <Route path='/Spiral/Purple'
          element={<SpiralPurple />}>
        </Route>
        <Route path='/Spiral/Alias'
          element={<SpiralAlias />}>
        </Route>
        <Route path='/Spiral/Hawaii'
          element={<SpiralHawaii />}>
        </Route>
        <Route path='/Spiral/HawaiiPlus'
          element={<SpiralHawaiiPlus />}>
        </Route>
        {/* LINES */}
        <Route path='/Lines/One'
          element={<Lines1 />}>
        </Route>
        <Route path='/Lines/Interstellar'
          element={<LinesInterstellar />}>
        </Route>
      </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
