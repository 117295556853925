import React, { useEffect, useRef, useState } from 'react';
import './Spiral - Hawaii.css';

function SpiralHawaii(props: { slow?: boolean }) {
  const canvas = useRef<HTMLCanvasElement>(null);
  const canvasB = useRef<HTMLCanvasElement>(null);
  const canvasC = useRef<HTMLCanvasElement>(null);
  const [dispose, setDispose] = useState(false);

  useEffect(() => {
    setDispose(false)
    if (props.slow && speedMultiplyer === _speedMultiplyer) speedMultiplyer = speedMultiplyer * 0.1;
    if (!props.slow && speedMultiplyer !== _speedMultiplyer) speedMultiplyer = _speedMultiplyer;
    console.log("Speed: ",speedMultiplyer)
  }, [props.slow, setDispose])

  useEffect(() => {
    if (canvas && canvasB && canvasC) {
      const context2D = canvas.current?.getContext('2d')
      const context2DB = canvasB.current?.getContext('2d')
      const context2DC = canvasC.current?.getContext('2d')

      if (context2D && context2DB && context2DC) {
        context2D.imageSmoothingQuality = "high";
        context2DB.imageSmoothingQuality = "high";
        context2DC.imageSmoothingQuality = "high";
        const loop = () => {
          render(context2D, context2DB, context2DC);
          if (!dispose) {
            requestAnimationFrame(() => loop());
          }
        }
        loop();
      }
    }
    return () => { setDispose(true); console.log('disposed') }
  }, [canvas, canvasB, canvasC, dispose])

  return (
    <div className="App Hawaii">
      <canvas ref={canvas} id="a" width={window.innerWidth} height={window.innerHeight - 5}></canvas>
      <canvas ref={canvasB} id="b" width={window.innerWidth} height={window.innerHeight - 5}></canvas>
      <canvas ref={canvasC} id="c" width={window.innerWidth} height={window.innerHeight - 5}></canvas>
    </div>
  );
}

const toRad = (dg: number): number => (Math.PI / 180) * dg;

const clear = (ctx: CanvasRenderingContext2D) => ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

var rotation = 0;
var directionForward = false;

const w = window.innerWidth;
const w_2 = w / 2;
const h = window.innerHeight;
const h_2 = h / 2;
const max = w >= h ? w : h; // Minimun size between width and height

// Configs   
const tot = max * 0.12; // Total Arcs
const maxRotation = 120; // Max radius Rotation before invert direction
const rotationStep = 1; // Valore di incremento/decremento rotazione in ogni frame
const _speedMultiplyer = 3 * (0.00005 * tot) * (w >= 992 ? 0.9 : 1.9); // Moltiplicatore velocità
var speedMultiplyer = _speedMultiplyer; // Moltiplicatore velocità

// Arc Config
const radius = (max / 1.75) // Dimensione dell'arco più grande (esterno)
const maxArcSize = 1;  // 360 = cerchio | 0 = niente
const lineWidth = 3;  // 360 = cerchio | 0 = niente
const radiusDiff = lineWidth * 2; // Radius Remover

// Constant
const colorPerc = 255 / tot;

function render(...ctxs: CanvasRenderingContext2D[]) {
  ctxs.forEach(ctx => clear(ctx));

  // Revert direction if needed
  if (rotation === maxRotation || rotation === -maxRotation) directionForward = !directionForward;

  // Compute rotation Diff
  const rotationDiff = directionForward ? rotation += (rotationStep * speedMultiplyer) : rotation -= (rotationStep * speedMultiplyer)

  for (let i = 1; i <= tot; i++) {

    const rotationK = -rotationDiff * (i / 4);

    const rKAlign = rotationK;
    const rKAlignB = 120 + rKAlign;                         // Clone
    const rKAlignC = 240 + rKAlign;                        // Clone

    // Single arc config
    const r = Math.max(radius - (i * radiusDiff), 1);
    const sA = toRad(rKAlign);
    const sAB = toRad(rKAlignB);                              // Clone
    const sAC = toRad(rKAlignC);
    const rPart = rotation / 20;
    const _maxArcS = maxArcSize + (rotation >= 0 ? rPart : -rPart);                          // Clone 
    const eA = toRad(_maxArcS + rKAlign);
    const eAB = toRad(_maxArcS + rKAlignB);                 // Clone 
    const eAC = toRad(_maxArcS + rKAlignC);                 // Clone 

    const _cPerc = colorPerc * i;

    // Segment color
    // Water Green
    const colorA = `rgba(40, ${255 - _cPerc}, 110)`;
    // Purple-Fuxia-Red
    const colorB = `rgba(${255 - (_cPerc)}, 0, 75 )`;    // Clone
    const y = 255 - (_cPerc)
    // Yellow
    const colorC = `rgba(${y}, ${y}, 0  )`;    // Clone

    for (let ctx of ctxs) {
      const cI = ctxs.indexOf(ctx);

      let [_s, _e, _c] = [0, 0, ''];
      switch (cI) {
        case 0:
          _s = sA;
          _e = eA;
          _c = colorA;
          break;
        case 1:
          _s = sAB;
          _e = eAB;
          _c = colorB;
          break;
        case 2:
          _s = sAC;
          _e = eAC;
          _c = colorC;
          break;
      }

      // Line Style
      ctx.strokeStyle = _c;
      ctx.lineWidth = lineWidth;

      // Path
      ctx.beginPath();
      ctx.arc(
        w_2,
        h_2,
        r, // Radius
        _s, // Start Angle
        _e // End Angle
      );
      ctx.stroke();
    }
  }
}

export default SpiralHawaii;
