import React, { useEffect, useRef } from 'react';
import { clearCanvas } from '../../../utils/canvas-utils';
import { h, h_2, max, w, w_2 } from '../../../utils/size-utils';
import './Lines - Interstellar.css';


// Number of stars in the screen
const nStars = max * 10; // Many star as the max screen size
// Utils array for mapping things
// const starsArr = Array(nStars).fill(null);

// External Line Width
const starMinSize = 0.3;
const starMaxSize = 2;

// Stars Computed Positions and Sizes
const starsInitPositions: { x: number, y: number, size: number }[] = [];

// Indicate if all the stars positions and size are computed
var allStarsLoaded = false;

var paused = true;

var pauseTimestamp = Date.now();

var pauseDuration = 1 * 1000; // 1 Secs

// Timeouts to dispose before reRun
const _timeouts: NodeJS.Timeout[] = [];

// Stars loaded per time frame
const starsEachTimeFrame = 200;

// Loading time (not perfect)
const loadSecs = 5; // Not to low

const easeSize = 0.02; // Max 0.5
const minSpeedFactor = 0.0001;


// Actual rotation direction
var directionForward = true;

// Reached value at which the direction is reversed (depends on baseArcSize)
const maxAnimationValue = max * 1000;

// Actual Animation Value (changes over time)
// var animationValue = maxAnimationValue;
var animationValue = 0;

// Incrase value for the animation (in the fastest moment)
const animStep = maxAnimationValue / 200;

// Offset positioning during animation
const offsetValue = maxAnimationValue;

// Time
const timeFrameStep = (starsEachTimeFrame / nStars) * 1000 * loadSecs;

var lastTimeExec = 0;

var _dispose = false; // Handle stop in the middle

/**
 * Inizializza, spezzandole nel tempo, tutte le stelle necessarie
 */
const lazyInitStarsPositions = async () => {
  /*eslint-disable */
  for (let i = 0; i < nStars; i++) {

    if (_dispose) return; // Handle stop in the middle

    const isTimeToAwait = i % starsEachTimeFrame === 0;
    if (isTimeToAwait) {
      const start = Date.now();
      await new Promise((resolve, reject) => {
        _timeouts.push(setTimeout(resolve, timeFrameStep - lastTimeExec));
      })

      lastTimeExec = Math.min(0, Date.now() - start - timeFrameStep);
    }

    const isTimeForFarAwayStar = i % 5 === 0;

    let rX = isTimeForFarAwayStar ? (Math.random() * 0.75) + (0.25 / 2) : Math.random();
    let rY = isTimeForFarAwayStar ? (Math.random() * 0.75) + (0.25 / 2) : Math.random();
    starsInitPositions.push({
      x: rX * w,
      y: rY * h,
      size: (isTimeForFarAwayStar
        ? starMinSize / 3
        : starMinSize) + (Math.random() * (starMaxSize - starMinSize)
        )
    });
  }
  /*eslint-disable */
  allStarsLoaded = true;
};

/**
 * Invert If Needed
 * 
 * If the animationValue reach the maxValue, inverts the direction
 */
const incrementAndInvertIfNeeded = (easeMlt: number) => {
  if (paused && Date.now() > (pauseTimestamp + pauseDuration)) {
    paused = false;
    animationValue = 0.001;
  }

  if (animationValue <= 0) {
    directionForward = true;
    if (!paused) {
      pauseTimestamp = Date.now();
      paused = true;
      return;
    }
  }
  if (animationValue >= maxAnimationValue) directionForward = false;


  if (!paused) {
    const animationStepEase = animStep * easeMlt;
    directionForward
      ? animationValue += animationStepEase
      : animationValue -= animationStepEase;
  }
}


export default function LinesInterstellar() {

  const canvas = useRef<HTMLCanvasElement>(null);
  // const [dispose, setDispose] = useState(false);

  useEffect(() => {
    // setDispose(false)
    _dispose = false;

    lazyInitStarsPositions();

    if (canvas) {
      const context2D = canvas.current?.getContext('2d')

      if (context2D) {
        context2D.imageSmoothingQuality = "high";
        const gradient = context2D.createLinearGradient(0, 0, w, h);
        gradient.addColorStop(0, 'rgb(200, 200, 230)');
        gradient.addColorStop(.5, 'rgb(50, 160, 230)');
        gradient.addColorStop(1, 'rgb(200, 200, 230)');
        context2D.strokeStyle = gradient;
        if (!_dispose) {
          const loop = () => {
            render(context2D);
            if (!_dispose) {
              requestAnimationFrame(() => loop());
            }
          }
          loop();
        }
      }
    }
    return () => {
      allStarsLoaded = false;
      _dispose = true;
      _timeouts.forEach(x => clearTimeout(x));
      Array(starsInitPositions.length).fill(null).forEach(x => starsInitPositions.pop());
      animationValue = 0;
      paused = true;
      console.log('disposed')
    }
  }, [canvas])

  return (
    <div className="App Hawaii">
      <canvas ref={canvas} id="a" width={window.innerWidth} height={window.innerHeight - 5}></canvas>
    </div>
  );
}

/**
 * Render
 * 
 * Renderizza ogni frame dell'animazione in base al valore di animazione
 * @param ctx Context 2D nel quale viene renderizzata l'animazione
 */
function render(ctx: CanvasRenderingContext2D) {
  clearCanvas(ctx);

  // Invert direction if needed

  const animPerc = (1 / maxAnimationValue) * animationValue;
  if (Math.random() < 0.001) console.log("animPerc", animPerc);

  // Animation Value increment (or decrement)
  let easeMlt = 1;

  if (animPerc <= easeSize) {
    // rotationStepMlt *= Math.min(Math.max(1, (1 + animPerc) * 10), minSpeedFactor);
    easeMlt = ((1 / easeSize) * animPerc);
  } else if (animPerc >= (1 - easeSize)) {
    easeMlt = 1 - (1 / easeSize) * (easeSize - animPerc - (1 - easeSize));
  }
  easeMlt = Math.min(1, Math.max(minSpeedFactor, easeMlt));

  if (allStarsLoaded) {
    // if (Math.random() < 0.01) console.log("rotationStepMlt, animPerc", easeMlt, animPerc);
    // Animation Step with ease-in-out  behaviour
    incrementAndInvertIfNeeded(easeMlt);

  }

  for (let i = 0; i < starsInitPositions.length; i++) {
    // Star Position
    const star = starsInitPositions[i];

    ctx.lineWidth = star.size + (star.size * animPerc);

    let [xMlt, yMlt] = [0, 0];
    // if (allStarsLoaded) {
    if (star.x < w_2) xMlt = -(1 / w_2) * (w_2 - star.x);
    else if (star.x > w_2) xMlt = -(1 / w_2) * (w_2 - star.x);
    if (star.y < h_2) yMlt = -(1 / h_2) * (h_2 - star.y);
    else if (star.y > h_2) yMlt = -(1 / h_2) * (h_2 - star.y);
    // }
    const [x, y] = [star.x + (offsetValue * animPerc * xMlt * 0.1), star.y + (offsetValue * animPerc * yMlt * 0.1)]
    // const [x, y] = [star.x + (offsetValue * easeMlt), star.y + (offsetValue * easeMlt)]

    const reverseSizeX = star.y <= h_2;
    const reverseSizeY = star.y <= h_2;
    const baseXSize =
      reverseSizeX
        ? x - star.size * Math.max(Math.abs(xMlt), 0.5)
        : x + star.size * Math.max(Math.abs(xMlt), 0.5);
    const baseYSize =
      reverseSizeY
        ? y - star.size * Math.max(Math.abs(yMlt), 0.5)
        : y + star.size * Math.max(Math.abs(yMlt), 0.5);

    // Define Arc
    ctx.beginPath();
    // ctx.arc(star.x, star.y, star.size / 2, 0, Math.PI * 2);
    ctx.moveTo(x, y);
    ctx.lineTo(
      (baseXSize + (animationValue * xMlt * easeMlt)),
      (baseYSize + (animationValue * yMlt * easeMlt)));
    ctx.stroke();
  }
}

